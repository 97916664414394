<template>
  <div class="columns is-multiline productDetail is-centered">
    <modal-image
      v-if="imageModal"
      :close="closeModal"
      :image="image"
    />
    <modal-carrousel
      v-if="carrouselModal"
      :images="detail.images"
      :current-image="currentImage"
      @close="closeCarrousel"
      @update-model-value="updateModelValue"
    />
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard is-flex">
      <div>
        {{detail.productName}} ({{ detail.name }}) - <span class="tag" :class="[detail.statusName == 'Pendiente' ? 'is-info' : '', detail.statusName == 'Preaprobado' ? 'is-warning' : '', detail.statusName == 'Activo' ? 'is-success' : '']">{{detail.statusName}}</span>
      </div>
      <div class="has-text-right">
        <label class="button-edit" @click="editProduct">
          <i class="material-icons click">edit</i>
        </label>
      </div>
    </div>
    <div class="column is-12 separator"></div>
    <div class="column is-8">
      <table class="table is-striped is-narrow is-fullwidth is-hoverable">
        <tr>
          <td class="label-table">Descripción</td>
          <td>{{detail.description}}</td>
        </tr>
        <tr>
          <td class="label-table">Marca</td>
          <td>{{ getBrandName(detail.brand) }}</td>
        </tr>
        <tr>
          <td class="label-table">Modelo</td>
          <td>{{getModelName(detail.model)}}</td>
        </tr>
        <tr>
          <td class="label-table">Precio</td>
          <td>$ {{formatMoney(detail.price || 0)}}</td>
        </tr>
        <tr>
          <td class="label-table">Stock</td>
          <td>{{detail.stock || 0}}</td>
        </tr>
        <tr>
          <td class="label-table">Condición</td>
          <td>{{parseInt(detail.condition, 10) === 1 ? 'Nuevo' : 'Seminuevo' }}</td>
        </tr>
        <tr>
          <td class="label-table">Lugar de deposito</td>
          <td>{{getPlaceDeposit(detail.place_deposit)}}</td>
        </tr>
        <tr>
          <td class="label-table">Fecha de alta</td>
          <td>{{formatDateTimestamp(detail.createdAt)}}</td>
        </tr>
        <tr>
          <td class="label-table">Hora de alta</td>
          <td>{{formatDateTimestampHour(detail.createdAt)}}</td>
        </tr>
        <tr>
          <td class="label-table">Caja</td>
          <td>{{ getEmojiValue(detail.box) }}</td>
        </tr>
        <tr>
          <td class="label-table">Accesorios</td>
          <td>{{ getEmojiValue(detail.accessories) }}</td>
        </tr>
        <tr>
          <td class="label-table">Número de teléfono</td>
          <td>
            <a :href="`https://wa.me/${detail.code_phone + detail.phone}`" target="_blank" v-if="detail.phone"
              style="display: flex; align-items: center; text-decoration: none; color: inherit;">
              {{ `${detail.code_phone ?? ''} ${detail.phone ?? 'N/A'}` }}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                style="margin-left: 8px;">
                <path fill="#25D366"
                  d="M12 2C6.477 2 2 6.477 2 12c0 2.257.743 4.34 2.029 6.055L2 22l4.09-1.083A9.955 9.955 0 0 0 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2zm.054 17.402a8.388 8.388 0 0 1-4.31-1.17l-.307-.185-3.036.804.818-2.957-.2-.312A8.358 8.358 0 0 1 3.6 11.935c0-4.634 3.764-8.4 8.4-8.4s8.4 3.766 8.4 8.4-3.764 8.4-8.4 8.4zm4.675-6.124c-.257-.13-1.526-.753-1.764-.837-.237-.084-.41-.13-.584.13-.175.257-.673.837-.823 1.006-.15.17-.308.191-.565.064-.257-.128-1.086-.4-2.07-1.277-.765-.682-1.28-1.523-1.432-1.78-.151-.257-.016-.397.114-.526.116-.116.257-.3.386-.447.13-.146.171-.257.257-.43.084-.175.042-.321-.021-.447-.064-.13-.583-1.403-.8-1.93-.209-.502-.422-.434-.583-.442-.15-.007-.321-.007-.493-.007-.171 0-.447.064-.68.3-.237.237-.9.879-.9 2.14s.92 2.473 1.051 2.645c.13.175 1.81 2.815 4.394 3.778.615.266 1.095.426 1.47.545.617.196 1.179.168 1.62.102.494-.074 1.526-.625 1.742-1.23.215-.604.215-1.124.15-1.23-.064-.106-.237-.171-.494-.3z" />
              </svg>
            </a>
            <span v-else>N/A</span>
          </td>
        </tr>
        <tr v-if="detail.rejected_message">
          <td class="label-table">Motivo de Preaprobación (Rechazo)</td>
          <td :class="[detail.statusName == 'Preaprobado'?'is-warning':'']">{{ detail.rejected_message }}</td>
        </tr>
      </table>
    </div>
    <div class="column is-4">
      <div class="box" style="box-shadow: none;">
        <div class="columns is-multiline has-text-centered">
          <div class="column is-12 is-paddingless is-marginless has-text-left titleCard">
            <div class="column is-12 is-paddingless is-marginless has-text-left titleCard">
              Informacion adicional
            </div>
            <div class="column is-12 separator"></div>
            <div class="field">
              <label class="label">Puerto de carga funcional</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="functionalChargingPort">
                    <option :value="null">Sin informacion</option>
                    <option :value="false">No</option>
                    <option :value="true">Si</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">Porcentaje de bateria</label>
              <div class="control">
                <input class="input" type="number" v-model="batteryPercentage" min="0" max="100">
              </div>
            </div>
            <div class="field">
              <label class="label">Celular liberado</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="unlockedCellPhone">
                    <option :value="null">Sin informacion</option>
                    <option :value="false">No</option>
                    <option :value="true">Si</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <table class="table is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable">
        <thead>
          <tr>
            <th class="has-text-centered">
              Categorías
            </th>
          </tr>
        </thead>
        <tr v-for="category in detail.categories" :key="category.id">
          <td>{{category.name}}</td>
        </tr>
      </table>
    </div>
    <div class="column is-6">
      <table class="table is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable">
        <thead>
          <tr>
            <th colspan="2" class="has-text-centered">
              Características
            </th>
          </tr>
        </thead>
        <tr v-for="detail in filteredProductDetails" :key="detail.label">
          <td class="label-table">{{ detail.label }}</td>
          <td>
            <template v-if="detail.value.startsWith('#')">
              <div class="color-circle" :style="{ backgroundColor: detail.value }"></div>
            </template>
            <template v-else>
              {{ detail.value }}
            </template>
          </td>
        </tr>
      </table>
    </div>
    <!-- <template v-if="this.detail.statusName === 'Vendido'">
      <div class="column is-12">
        <table class="table is-striped is-narrow is-fullwidth is-hoverable">
          <tr>
            <td class="label-table">Comprador</td>
            <td>
              <router-link :to="{ name: 'detalle-usuario', params: { userId: detail.saleDetail.buyerId } }">
                <strong class="userName">{{detail.saleDetail.buyerUsername}}</strong>
              </router-link>
            </td>
          </tr>
          <tr>
            <td class="label-table">Estatus</td>
            <td>{{detail.saleDetail ? detail.saleDetail.label : ''}}</td>
          </tr>
          <tr>
            <td class="label-table">Fecha de venta</td>
            <td>{{detail.saleDetail ? formformatDateTimestampatDate(detail.saleDetail.createdAt) : ''}}</td>
          </tr>
          <tr>
            <td class="label-table">Comprador confirmado</td>
            <td>{{detail.saleDetail}}</td>
            <td>{{detail.saleDetail.buyer_confirm ? 'Si' : 'No'}}</td>
          </tr>
          <tr>
            <td class="label-table">Vendedor confirmado</td>
            <td>{{detail.saleDetail.seller_confirm ? 'Si' : 'No'}}</td>
          </tr>
          <tr>
            <td class="label-table">Dirección</td>
            <td>{{detail.saleDetail.address_id ? detail.saleDetail.neighborhood : 'Entrega personal'}}</td>
          </tr>
        </table>
      </div>
    </template> -->
    <div class="column is-12 has-text-centered" v-if="detail.images.length > 0">
      <strong>Fotos del producto </strong>
    </div>
    <template>
      <div class="column is-3" v-for="(image, index) of detail.images" :key="index">
        <div :style="`background-image: url('${image.url}')`" class="productImage" @click="openCarrousel(index)"></div>
        <p class="has-text-centered title-image" :class="[image.main ? 'main' : '']">{{image.main ? 'Portada' : 'Foto'}}</p>
      </div>
    </template>
    <div class="column is-12">
      <strong>Ubicación</strong>
      <product-ubication
        :lat="detail.latitude"
        :lng="detail.longitude"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import mixins from '../../mixin/validateDate'
import { mapActions } from 'vuex'

export default {
  mixins: [mixins],
  props: {
    detail: {
      type: Object,
      required: true
    },
    editProduct: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    ModalImage: () => import('@/components/Products/ModalImage'),
    ModalCarrousel: () => import('@/components/Products/ModalCarrousel'),
    ProductUbication: () => import('@/components/Products/ProductUbication')
  },
  data () {
    return {
      PENDING_STATUS: 1,
      imageModal: false,
      image: null,
      carrouselModal: false,
      currentImage: 0,
      functionalChargingPort: this.detail.charging_port ?? null,
      batteryPercentage: this.detail.battery ?? null,
      unlockedCellPhone: this.detail.unlocked_phone ?? null
    }
  },
  computed: {
    productDetails () {
      return [
        { label: 'Color', value: this.detail.color_code ? this.detail.color_code : (this.detail.color_label ? this.detail.color_label : null) },
        { label: 'Storage', value: this.detail.storage ? this.detail.storage : null }
      ]
    },
    filteredProductDetails () {
      return this.productDetails.filter(detail => detail.value)
    }
  },
  watch: {
    functionalChargingPort: 'debouncedUpdateProductDetails',
    batteryPercentage: 'debouncedUpdateProductDetails',
    unlockedCellPhone: 'debouncedUpdateProductDetails'
  },
  methods: {
    ...mapActions(['addTemporalNotification']),
    getModelName (value) {
      const models = {
        1: 'X',
        2: 'XR',
        3: 'XS',
        4: 'XS Max',
        5: '11',
        6: '11 Pro',
        7: '11 Pro Max',
        8: 'SE (2nd generation)',
        9: '12 Mini',
        10: '12',
        11: '12 Pro',
        12: '12 Pro Max',
        13: '13 Mini',
        14: '13',
        15: '13 Pro',
        16: '13 Pro Max',
        17: '14 Mini',
        18: '14',
        19: '14 Pro',
        20: '14 Pro Max',
        30: '15',
        31: '15 Mini',
        32: '15 Pro',
        33: '15 Pro Max',
        21: 'Galaxy S22 Ultra',
        22: 'Galaxy S22+',
        23: 'Galaxy S22',
        24: 'Galaxy Z Fold3',
        25: 'Galaxy Z Flip3',
        26: 'Galaxy A52 5G',
        27: 'Galaxy A32 5G',
        28: 'Galaxy A12',
        29: 'Galaxy A02s'
      }
      return models[value] || value
    },
    getEmojiValue (value) {
      if (value === true) {
        return '✅'
      } else if (value === false) {
        return '❌'
      } else {
        return value
      }
    },
    getBrandName (value) {
      switch (value) {
        case 1:
          return 'Samsung'
        case 2:
          return 'iPhone'
        default:
          return value
      }
    },
    getPlaceDeposit (value) {
      switch (value) {
        case '1':
          return 'DHL'
        case '2':
          return 'WeWork Montes Úrales'
        case '3':
          return 'German Centre  Av. Santa Fe 170'
        default:
          return value
      }
    },
    formatMoney (amount) {
      return parseInt(amount).toLocaleString('en', { currency: 'USD' })
    },
    showImageModal (image) {
      this.image = image
      this.imageModal = true
    },
    closeModal () {
      this.imageModal = false
    },
    setCategoriesList (categories) {
      return categories.reduce((accum, category, index) => {
        const coma = index === 0 ? '' : ', '
        return `${accum}${coma}${category.name}`
      }, '')
    },
    updateProductDetails () {
      if (this.batteryPercentage < 0 || this.batteryPercentage > 100) {
        this.addTemporalNotification({ type: 'info', message: 'El porcentaje de la batería debe estar entre 0 y 100.' })
        this.batteryPercentage = this.detail.battery ?? null
        return
      }

      const payload = {
        charging_port: this.functionalChargingPort,
        battery: this.batteryPercentage,
        unlocked_phone: this.unlockedCellPhone
      }

      this.action(payload)
    },
    debouncedUpdateProductDetails: debounce(function () {
      this.updateProductDetails()
    }, 1000),
    openCarrousel (index) {
      this.currentImage = index
      this.carrouselModal = true
    },
    closeCarrousel () {
      this.carrouselModal = false
    },
    updateModelValue (prop) {
      this.currentImage = prop
    }
  }
}
</script>

<style scoped lang="scss">
 .productImage {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      transform: scale(1.09);
    }
  }
  strong {
    font-size: 14px;
    color: var(--secondary-color);
  }
  .productDetail {
    font-size: 12px;
  }
  .click {
    cursor: pointer;
  }
  .title-image {
    font-size: 14px;
    color: #354676;
    font-weight: 500;
    padding-bottom: 5px;
    margin-bottom: 10px;
    &.main {
      color: #cd3d27;
      font-weight: 600;
    }
  }
  .is-portada {
    border: 1px solid var(--primary-color);
    padding: 5px;
  }
  .label-table {
    font-weight: 700;
    text-align: right;
  }
  .button-edit {
    margin-right: 5px;
    &:hover {
      color: #cd3d27
    }
  }
  .is-flex {
    flex-direction: row;
    justify-content: space-between;
  }
  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
</style>
